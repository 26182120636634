import Swiper, {Navigation, SwiperOptions} from 'swiper';

const items = document.getElementsByClassName('product');
const descriptions = document.getElementsByClassName('product-description');
const header = document.getElementById('index-header');

const ACTIVE_CLASS_ITEM = 'product_active';
const DISABLED_CLASS_ITEM = 'product_disabled';
const ACTIVE_CLASS_HEADER = 'index__left_active';

const ACTIVE_CLASS_DESCRIPTION = 'product-description_active';

const hideMessage = () => {
    window.clearTimeout(messageTimeout);
    const message = document.getElementById('index-message');
    if (!message) {
        return;
    }
    message.classList.add('hide');
};
const messageTimeout = window.setTimeout(hideMessage, 5000);

const onSlideChange = (swiper: Swiper) => {
    if (!header) {
        return;
    }
    hideMessage();

    if (swiper.activeIndex > 0) {
        header.classList.add(ACTIVE_CLASS_HEADER);
    } else {
        header.classList.remove(ACTIVE_CLASS_HEADER);
    }
};

const onSliderMove = (swiper: Swiper) => {
    if (!header) {
        return;
    }
    hideMessage();

    header.classList.add(ACTIVE_CLASS_HEADER);
};

const SWIPER_PARAMS: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    resistanceRatio: 0.25,
    navigation: {
        prevEl: '#slider-prev',
        nextEl: '#slider-next'
    },
    on: {
        slideChange: onSlideChange,
        sliderFirstMove: onSliderMove
    }
};

const SWIPER_PARAMS_PARTNERS: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 34,
    resistanceRatio: 0.25,
    navigation: {
        prevEl: '#slider-prev-partners',
        nextEl: '#slider-next-partners'
    },
    on: {
        slideChange: onSlideChange,
        sliderFirstMove: onSliderMove
    }
};

const onClick = (evt: Event) => {
    if (!items || !descriptions) {
        return;
    }
    hideMessage();

    const target = evt.target as HTMLElement;
    const index = parseInt(target.dataset.index || '0', 10);
    // items[index].classList.add(ACTIVE_CLASS_ITEM);
    // descriptions[index].classList.add(ACTIVE_CLASS_DESCRIPTION);

    if (items[index].classList.contains(ACTIVE_CLASS_ITEM)) {
        Array.from(items).forEach((i: Element) => {
            i.classList.remove(ACTIVE_CLASS_ITEM);
            i.classList.remove(DISABLED_CLASS_ITEM);
        });
        Array.from(descriptions).forEach((d: Element) => {
            d.classList.remove(ACTIVE_CLASS_DESCRIPTION);
        });
    } else {
        Array.from(items).forEach((i: Element) => {
            i.classList.remove(ACTIVE_CLASS_ITEM);
            i.classList.add(DISABLED_CLASS_ITEM);
        });
        Array.from(descriptions).forEach((d: Element) => {
            d.classList.remove(ACTIVE_CLASS_DESCRIPTION);
        });
        items[index].classList.add(ACTIVE_CLASS_ITEM);
        descriptions[index].classList.add(ACTIVE_CLASS_DESCRIPTION);
    }
};

export const slider = () => {
    Array.from(items).forEach((i) => {
        i.addEventListener('click', onClick);
    });

    const el = document.getElementById('slider') as HTMLElement;
    Swiper.use([Navigation]);
    const swiper = new Swiper(el, SWIPER_PARAMS);

    const elPartners = document.getElementById('slider-partners') as HTMLElement;
    const swiperParners = new Swiper(elPartners, SWIPER_PARAMS_PARTNERS);
};

// banner

const closeButton = document.getElementById('birthday-close');
const birthdayLink = document.getElementById('birthday-link');
const birthdayBlockWrapper = document.getElementById('birthday-wrapper');

const startFriday = new Date('2024-01-01T18:00:00').getTime();
const endFriday = new Date('2025-01-05T23:59:59').getTime();

const isFridayVisible = endFriday - new Date().getTime() > 0 && startFriday - new Date().getTime() < 0;
if (isFridayVisible) {
    closeButton?.classList.add('birthday__close_friday');
    birthdayLink?.classList.add('birthday_friday');
} else {
    birthdayBlockWrapper?.classList.add('birthday__wrapper_hidden');
}

// Add click event listener
closeButton?.addEventListener('click', () => {
    // Remove the birthday block from the DOM
    birthdayBlockWrapper.classList.remove('show');
    birthdayBlockWrapper.classList.add('close');
});

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        birthdayBlockWrapper.classList.add('show');
    }, 2000);
});
